const messages = {
	websiteTitle: "Burg Giebichenstein Kunsthochschule Halle",

	"pageTitleLabel.studiengang": "Study course",
	"pageTitleLabel.studienrichtung": "Study programme",
	"pageTitleLabel.schwerpunkt": "Focus",
	"pageTitleLabel.einrichtung": "Facility",

	"sectionProjectsLabel.studiengang": "Projects by the study course {title}",
	"sectionProjectsLabel.studienrichtung":
		"Projects by the study programme {title}",
	"sectionProjectsLabel.schwerpunkt": "Projects by the study focus {title}",
	"sectionProjectsLabel.einrichtung": "Projects in the facility {title}",

	"navigation.works": "Works",
	"navigation.archive": "Archive",

	"energySlider.energySaverMode": "Energy-saving mode",
	"energySlider.animationMode": "Animation mode",
	calendar: "Calendar",
	"calendar.inSection": "Events {section} ({count})",
	"calendar.today": "Today ({count})",
	"calendar.highlightsInNearFuture": "Highlights in the near future",
	"calendar.ongoing": "Ongoing ({count})",
	"calendar.upcoming": "Upcoming ({count})",
	"calendar.allEvents": "All dates",
	"loginStatus.loading": "Loading login…",
	"loginStatus.loggedIn": "Logged in as {username}",
	"loginStatus.login": "Login",
	"frication.open": "Open Friction",
	"ceGroup.expand": "Expand",
	"ceGroup.collapse": "Collapse",
	"ceText.expand": "Expand text",
	"ceText.collapse": "Collapse text",
	"ceImage.noImage": "There's no image to show",

	menu: "Menü",
	all: "All",
	search: "Search",
	loadMore: "Load more…",
	more: "More",
	noResults: "No results found!",

	"toolbar.search": "Search",
	"toolbar.application": "Application",
	"toolbar.Lehrangebote": "Courses",
	"toolbar.Studienarbeiten": "Projects",
	"toolbar.media": "Media",
	"toolbar.press": "Press",
	"toolbar.tools": "Tools",
	"toolbar.map": "Map",
	"toolbar.toggleCineMode": "Toggle CineMode",

	"solr.result.pagesTitleSuffix": " (Page)",

	"solr.title.default": "Search",
	"solr.title.article": "Articles",
	"solr.title.event": "Events",
	"solr.title.lehrangebot": "Courses",
	"solr.title.person": "Persons",
	"solr.title.personType": "Persons",
	"solr.title.former": "Former",
	"solr.title.project": "Projects",
	"solr.title.publication": "Publications",
	"solr.title.sectionProject": "Projects",
	"solr.title.sectionArchive": "Archive",
	"solr.title.media": "Media",
	"solr.title.aToZ": "Burg A—Z",
	"solr.title.news": "News",

	"solr.all.default": "All results",
	"solr.all.article": "All articles",
	"solr.all.event": "All events",
	"solr.all.lehrangebot": "All courses",
	"solr.all.person": "All persons",
	"solr.all.personType": "All persons",
	"solr.all.former": "Former",
	"solr.all.project": "All projects",
	"solr.all.publication": "All publications",
	"solr.all.sectionProject": "All projects",
	"solr.all.sectionArchive": "All articles",
	"solr.all.news": "All articles",
	"solr.all.media": "All media",

	"eventType.default": "Event",
	"eventType.1": "Jour Fixe",
	"eventType.2": "Cinema",
	"eventType.3": "Presentation",
	"eventType.4": "Examination",
	"eventType.5": "Scholarship",
	"eventType.6": "Lecture",
	"eventType.7": "Competition",
	"eventType.8": "Event (Other)",
	"eventType.9": "Exhibition",
	"eventType.10": "Diploma",
	"eventType.11": "Board",

	"projectType.default": "Project",
	"projectType.groupProject": "Group project",
	"projectType.1": "Student project 1st year",
	"projectType.2": "Student project 2nd year",
	"projectType.3": "Student project 3rd year",
	"projectType.4": "Bachelor",
	"projectType.5": "Free project",
	"projectType.6": "Student project",
	"projectType.7": "Master",
	"projectType.9": "Diploma",
	"projectType.10": "Private",
	"projectType.11": "Research project",
	"projectType.12": "Raised project",
	"projectType.13": "Student project",
	"projectType.14": "State exam",

	"personPage.contact": "Location/Contact",

	"projectEssentialsTitle.backToProjects": "Back to projects",
	"projectEssentialsTitle.backToGroupProject": "Back to group project",
	"projectEssentialsTitle.groupProject": "Group project",
	"projectEssentialsTitle.project": "Project",

	"studentsEssentialsTitle.students": "Students",
	"studentsEssentialsTitle.student": "Student",

	"vimeoVideo.noConsent":
		"No consent given for Vimeo. Can't show embedded video. {br} <showConsentModal>Open cookie settings</showConsentModal>{br}<videoLink>Watch Video on Vimeo</videoLink>",
	"podigeePodcast.noConsent":
		"No consent given for Podigee. Can't show embedded podcast. {br} <showConsentModal>Open cookie settings</showConsentModal>{br}<podcastLink>Listen Podcast on Podigee</podcastLink>",
	"youtubeVideo.noConsent":
		"No consent given for Youtube. Can't show embedded video. {br} <showConsentModal>Open cookie settings</showConsentModal>{br}<videoLink>Watch Video on Youtube</videoLink>",

	addToCalendar: "Add to calendar",

	courseSection: "Study Course / Programme",
	courseEcts: "ECTS/LN",
	courseEinschreibefrist: "Registration deadline",
	courseRegistrationStart: "Registration start",
	courseRegistrationEnd: "Registration end",
	courseBegin: "Course Begin",
	coursePlace: "Place",
	courseTime: "Time",
	courseBesprechungszeit: "Consultation time",
	coursePflichtkennzeichen: "Mandatory",
	courseRequirements: "Requirements",
	courseErgaenzungsangebote: "Additional courses",
	courseStudienjahr: "Course year",
	courseGuests: "Guests",
	courseFinalDegree: "Final degree",
	courseBenotet: "Grading",
	courseBezeichnung: "Module name / Subject name",
	courseKontakt: "Contact",
	courseModulAngebotTitle: "Design module / Art offer",
	courseSemester: "Term",
	courseSemesterTyp: "Semester type",
	courseSemesterYear: "Semester year",
	courseVeranstaltungsart: "Type of event",
	courseWochentypString: "Week type",
	courseZeitenUndTermine: "Times and dates",

	place: "Place",
	time: "Time",

	urlFormer: "/en/university/staff/former-staff",

	"courseSemesterTyp.WiSe": "Winter term",
	"courseSemesterTyp.SoSe": "Summer term",

	"relatedPersonsTitle.teachers": "Teachers",
	"relatedPersonsTitle.default": "Persons",

	"form.submit": "Submit",
	"form.previous": "Previous page",
	"form.next": "Next page",
	"form.submitted": "Successfully sent!",

	"relatedProjects.subProjects.titleHead": "Ergebnisse",
	"relatedProjects.subProjects.titleLabel":
		"Studienarbeiten des Gruppenprojekts „{articleTitle}”",
	"relatedProjects.siblingProjects.titleHead":
		"Weitere Ergebnisse des Gruppenprojekts {articleParentTitle, select, undefined {} other { „{articleParentTitle}”}}",

	"home.media.title": "Media",
	"home.media.allMedia": "All media",

	"newsletterSignup.email": "Email",
	"newsletterSignup.submit": "Register for newsletter",

	"aToZ.forTeachers": "For teachers",
	"aToZ.forManagement": "For management",
	"aToZ.forStudents": "For students",
	"aToZ.applications": "Applications",

	"mainNav.kunst": "Art",
	"mainNav.design": "Design",
	"mainNav.studium": "Study",
	"mainNav.werkstatten": "Workshops",
	"mainNav.einrichtungen": "Facilities",
	"mainNav.personen": "People",
	"mainNav.institution": "Institution",
} as const satisfies Record<string, string>;

export default messages;
